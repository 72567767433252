import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import PageHelmet from "../components/PageHelmet";
import HeroImage from "../components/common/HeroImage";

import heroImg from "../images/hero/hero-stickledown.jpg";
import logoImg from "../images/icrpc-header-logo-150px.png";

const HomePage: React.FC = () => (
  <>
    <PageHelmet />
    <HeroImage
      bgURL={heroImg}
      fg={<img className="hero-logo" src={logoImg} alt="ICRPC Logo" />}
      size="large"
      bgPosition={60}
    />
    <Container>
      <Row>
        <Col>
          <h1 className="icrpc-title">Welcome to ICRPC</h1>
          <p className="lead">
            Imperial College &amp; St Mary's Rifle &amp; Pistol Club (ICRPC) is
            a multi-discipline Home Office Approved Target Shooting Club. Our
            aim is to make target shooting as fun, accessible, and affordable as
            possible to our members.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <h2 className="icrpc-title">What we do</h2>
          <p>
            At ICRPC, we take part in nearly every shooting discipline open to a
            university club, including Smallbore and Fullbore Target Rifle,
            Gallery Rifle, Clay Pigeon Shooting, and Air Pistol.
          </p>
          <p>
            We run Gallery Rifle, Smallbore TR, and Air Pistol every Wednesday
            at our range in Central London. The facility was built in 2013, and
            is quick and easy to get to on public transport.
          </p>
          <p>
            In addition to our weekly indoor activities, we also run regular
            trips to the National Shooting Centre in Bisley to shoot fullbore
            target and service rifles at distances from 100&ndash;1000 yards. We
            also run regular trips to clay grounds around London to shoot
            English Sporting Clays.
          </p>
          <p>
            You don't need your own firearm to shoot with us. We have all the
            necessary equipment and accept members of all skill levels. Most
            people who join the club have never picked up a firearm before, so
            why not come and give it a go?
          </p>

          <h2 className="icrpc-title">Interested in joining?</h2>
          <p>
            To be eligible to join ICRPC, you must be a member of{" "}
            <a
              href="https://www.imperialcollegeunion.org/activities/a-to-z/rifle-pistol"
              target="_blank"
              rel="noreferrer"
            >
              SPI Rifle &amp; Pistol
            </a>
            , which requires membership of{" "}
            <a
              href="https://www.imperialcollegeunion.org/activities/imperial-athletes"
              target="_blank"
              rel="noreferrer"
            >
              Imperial Athletes
            </a>
            . To join Imperial Athletes, one must be a member of Imperial
            College Union. All current Imperial College London students are
            automatically members of the Union. If you are a member of staff,
            alumnus, student of an associated institution, or other eligible
            individual you must purchase Life/Associate membership of the Union
            in order to join.
          </p>
          <p>
            <Button as={Link} to="/join" variant="primary" block>
              Click here for more information
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default HomePage;
