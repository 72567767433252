import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import PageHelmet from "../components/PageHelmet";
import HeroImage from "../components/common/HeroImage";

// Image imports
import heroImg from "../images/hero/hero-csr.jpg";
import img_SBTR_Shooting from "../images/discipline/sb-range-behind.jpg";
import img_SBTR_Targets from "../images/discipline/sb-targets.jpg";
import img_FBTR_Wide from "../images/discipline/fb-stickledown-wide.jpg";
import img_FBTR_Closeup from "../images/discipline/fb-stickledown-closeup.jpg";
import img_GRP_Range from "../images/discipline/gr-range.jpg";
import img_GRP_Melville from "../images/discipline/gr-melville-crop.jpg";
import img_Clay_Break from "../images/discipline/clays-break.jpg";
import img_AP_Product from "../images/discipline/ap-product.jpg";
import img_SR_MosinBoom from "../images/discipline/sr-mosinboom.jpg";
import img_SR_Standing from "../images/discipline/sr-standing.jpg";

interface ImageRowProps {
  children: React.ReactNode;
  image: string;
  imageSide: "left" | "right";
}

const ImageRow: React.FC<ImageRowProps> = ({ children, image, imageSide }) => (
  <Row style={{ marginBottom: "1rem" }}>
    <Col md={{ span: 7, order: imageSide === "left" ? 2 : 1 }} xs={12}>
      {children}
    </Col>
    <Col md={{ span: 5, order: imageSide === "left" ? 1 : 2 }} xs={12}>
      <Image src={image} fluid />
    </Col>
  </Row>
);

interface TwoImageRowProps {
  image1: string;
  image2: string;
}
const TwoImageRow: React.FC<TwoImageRowProps> = ({ image1, image2 }) => (
  <Row style={{ marginBottom: "1rem" }}>
    <Col md={6} xs={12}>
      <Image src={image1} fluid />
    </Col>
    <Col md={6} xs={12}>
      <Image src={image2} fluid className="mt-md-0 mt-2" />
    </Col>
  </Row>
);

const DisciplinesPage: React.FC = () => (
  <>
    <PageHelmet
      title="Disciplines"
      canonicalPath="/disciplines"
      description="Learn more about the disciplines ICRPC shoots"
    />
    <HeroImage
      bgURL={heroImg}
      fg={<span className="h1 icrpc-title">Disciplines</span>}
      size="small"
    />
    <Container>
      <Row>
        <Col>
          <h1 className="icrpc-title">Smallbore TR</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Smallbore Target Rifle is a precision discipline, shot with .22
            target rifles. The discipline is shot prone (lying on your front),
            and shooters wear jackets and slings to help support the rifle. The
            discipline can be shot at several distances, but we mostly shoot it
            at 25 yards on our indoor range in Central London.
          </p>
          <p>
            The targets are small, with the bullseyes only 12.5mm in diameter.
            The shooter fires one shot on each 'diagram' after zeroing, giving a
            score out of 100. This is a BUCS discipline, so there are
            opportunities to represent yourself and ICRPC against some of the
            best university shooters in the country! ICRPC has a successful
            history in this discipline, regularly reaching the team finals and
            placing in the top 3.
          </p>
        </Col>
      </Row>
      <TwoImageRow image1={img_SBTR_Shooting} image2={img_SBTR_Targets} />

      <Row>
        <Col>
          <h1 className="icrpc-title">Fullbore TR</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Fullbore Target Rifle is the big brother of Smallbore TR. This is a
            discipline shot on outdoor ranges at distances between 300 and 1000
            yards. The Club regularly runs trips to the National Shooting
            Centre, Bisley, where we train and enter competitions through the
            summer shooting season.
          </p>
          <p>
            This discipline builds on the principles of marksmanship, and adds
            in the requirement to adjust for shooting conditions including wind,
            rain, and the occasional snow storm. This is also a BUCS discipline,
            with a two day competition being held during the Imperial Meeting
            (no, not named after Imperial College!) each year in July.
          </p>
        </Col>
      </Row>
      <TwoImageRow image1={img_FBTR_Wide} image2={img_FBTR_Closeup} />

      <Row>
        <Col>
          <h1 className="icrpc-title">GR&amp;P</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Gallery Rifle &amp; Pistol is a relatively new discipline, being
            developed since 1997 when handguns were banned in the UK. It builds
            on competitions previously shot with hangduns, which we now shoot
            with 'pistol calibre' rifles, such as .22 LR, .38 sp, and .44 mag,
            or if you have an FAC, long-barreled pistols and revolvers. The
            discipline is varied, encompassing precision events, timed shoots,
            and various courses of fire. The most basic competitions are shot at
            25m with a static target. The most complex are shot in several
            stages, at 7&ndash;50m and include shooting in different positions
            (standing, sitting, kneeling and prone) as well as shooting from
            both the strong and weak shoulder.
          </p>
          <p>
            GR&amp;P competitions run throughout the year from March to October
            and ICRPC sends individuals and teams to several. We enter the
            GR&amp;P Imperial Meeting every year, shooting against Oxford and
            Cambridge (and we hope some more universities soon!). Last year, we
            took home all four trophies we shot for!
          </p>
        </Col>
      </Row>
      <TwoImageRow image1={img_GRP_Range} image2={img_GRP_Melville} />

      <Row>
        <Col>
          <h1 className="icrpc-title">Clays</h1>
        </Col>
      </Row>
      <ImageRow image={img_Clay_Break} imageSide="right">
        <p>
          Clay Pigeon shooting uses shotguns which fire hundreds of small
          pellets in a 'pattern', the aim being to predict where the 'bird' is
          going to fly, and shoot ahead of it with the pattern. There are
          several disciplines of clay shooting; the Club mostly shoots English
          Sporting, which gives a varied and unpredictable round of shooting. On
          a typical shoot you can expect to fire 50&ndash;100 cartridges, and
          hopefully hit an equal number of 'birds'! The Club travels to several
          clay grounds around the outskirts of London for practice, and we enter
          two major competitions each year&mdash;Christmas Cup, and Clay BUCS.
          This is a challenging but extremely fun discipline that everyone
          should try!
        </p>
      </ImageRow>

      <Row>
        <Col>
          <h1 className="icrpc-title">AP</h1>
        </Col>
      </Row>
      <ImageRow image={img_AP_Product} imageSide="left">
        <p>
          Air Pistol is another precision discipline shot by the Club. It
          involves firing at a target using a pistol with 'iron sights',
          standing, using only one hand. The Club operates a 10m air pistol
          range, though the discipline can also be shot at 25m on the main
          range. The bull on the targets for AP at 10m is only 11mm in diameter,
          making this a real challenge! It is a great discipline to take part in
          for all shooters as it helps to build the principles of marksmanship.
          The Club also enters a postal league every year against other
          university teams, as well as facilitating and encouraging entry into
          national postal leagues and competitions run by the NSRA.
        </p>
      </ImageRow>

      <Row>
        <Col>
          <h1 className="icrpc-title">C/HSR</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Civilian and Historic Service Rifle is another relatively new
            discipline, involving the use of rifles either historically issued
            in military service (such as the Lee Enfield No.4), or rifles based
            on modern military designs and modified for precision shooting.
            These disciplines are shot in competition at 25&ndash;600 yards on a
            variety of targets. Part of the challenge is knowing where to set
            your sights or aim off at every distance!
          </p>
          <p>
            Shooters also have to contend with shooting in different positions,
            reading the wind, tolerating the weather, and often some mild
            exercise in the form of 'run-downs', where you start behind the
            firing line and have to sprint to reach your rifle to shoot. The
            Club regularly runs service rifle trips to Bisley, usually ending
            the proceedings with a Lee Enfield 'Mad Minute'.
          </p>
        </Col>
      </Row>
      <TwoImageRow image1={img_SR_MosinBoom} image2={img_SR_Standing} />
    </Container>
  </>
);

export default DisciplinesPage;
