import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Link } from "react-router-dom";

const PageNotFoundPage: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <h1>Page Not Found</h1>
        <p className="lead">
          The page you are trying to access does not exist.{" "}
          <Link to="/">Click here to go back to the homepage</Link>.
        </p>
        <p>
          If you got here from a link elsewhere on the site, please contact us
          detailing how you arrived here so we can remove the broken link.
        </p>
      </Col>
    </Row>
  </Container>
);

export default PageNotFoundPage;
