import React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import ContactPage from "./pages/ContactPage";
import DisciplinesPage from "./pages/DisciplinesPage";
import HomePage from "./pages/HomePage";
import JoinPage from "./pages/JoinPage";
import PageNotFoundPage from "./pages/PageNotFoundPage";

const AppRoutes: React.FC = () => (
  <RouterRoutes>
    <Route path="/" element={<HomePage />} />
    <Route path="/disciplines" element={<DisciplinesPage />} />
    <Route path="/join" element={<JoinPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="*" element={<PageNotFoundPage />} />
  </RouterRoutes>
);

export default AppRoutes;
