import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ContactForm, { ContactFormValues } from "../components/ContactForm";
import HeroImage from "../components/common/HeroImage";
import PageHelmet from "../components/PageHelmet";

import heroImg from "../images/hero/hero-fullbore.jpg";

const ContactPage: React.FC = () => {
  const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState<string | null>(
    null
  );

  const onSubmit = async (values: ContactFormValues): Promise<void> => {
    setSubmissionSuccess(false);
    setSubmissionError(null);

    return fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["error"] === null) {
          console.log("Success: ", data);
          setSubmissionSuccess(true);
        } else {
          console.error("Error:", data);
          setSubmissionError(
            `Error code ${data["error"]}: ${data["msg"]}. Please try again later`
          );
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setSubmissionError(
          "An error occurred contacting the server. Please try again later"
        );
      });
  };

  return (
    <>
      <PageHelmet
        title="Contact"
        canonicalPath="/contact"
        description="Get in touch with ICRPC"
      />
      <HeroImage
        bgURL={heroImg}
        fg={<span className="h1 icrpc-title">Contact Us</span>}
        size="small"
        bgPosition={25}
      />
      <Container className="pb-2">
        <Row>
          <Col>
            <p className="lead">
              If you wish to contact ICRPC, simply fill in this form and we'll
              get back to you as soon as possible.
            </p>
            <ContactForm
              submissionSuccess={submissionSuccess}
              submissionError={submissionError}
              onSubmit={onSubmit}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactPage;
