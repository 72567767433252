import React from "react";

interface HeroImageProps {
  bgURL: string;
  fg: React.ReactNode;
  size: "large" | "small";
  centeredFg?: boolean;
  bgPosition?: number;
}

const HeroImage: React.FC<HeroImageProps> = ({
  bgURL,
  fg,
  size,
  centeredFg = true,
  bgPosition = 50,
}) => {
  return (
    <div className={`hero-header-container hero-${size}`}>
      <div
        className="hero-header-bg"
        style={{
          backgroundImage: `url(${bgURL})`,
          backgroundPosition: `50% ${bgPosition}%`,
        }}
      />
      <div
        className="hero-header-fg"
        style={{
          color: "white",
        }}
      >
        {centeredFg ? <div className="center-flex">{fg}</div> : fg}
      </div>
    </div>
  );
};

export default HeroImage;
