import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ExternalLinkButton from "../components/ExternalLinkButton";
import PageHelmet from "../components/PageHelmet";
import HeroImage from "../components/common/HeroImage";

import heroImg from "../images/hero/hero-clays.jpg";

const UnionMembershipButtons: React.FC = () => (
  <>
    <ExternalLinkButton
      href="https://www.imperialcollegeunion.org/activities/imperial-athletes"
      text="Click here for Imperial Athletes membership"
    />
    <ExternalLinkButton
      href="https://www.imperialcollegeunion.org/shop/csp/rifle-pistol/spi-rifle-pistol-membership-2022-23"
      text="Click here for SPI Rifle &amp; Pistol membership"
    />
  </>
);

const ApplicationFormButton: React.FC = () => (
  <ExternalLinkButton
    href="https://join.icrpc.org.uk/"
    text="Click here to go to the application form"
  />
);

const JoinPage: React.FC = () => (
  <>
    <PageHelmet
      title="Join"
      canonicalPath="/join"
      description="Joining ICRPC is simple, anybody who is a member of Imperial College Union can apply for membership"
    />
    <HeroImage
      bgURL={heroImg}
      fg={<span className="h1 icrpc-title">Join ICRPC</span>}
      size="small"
    />
    <Container>
      <Row>
        <Col>
          <p className="lead">
            Joining ICRPC is simple, and anybody who is a member of Imperial
            College Union can become a member.
          </p>
          <p>
            All current Imperial College students are automatically members of
            the Union. If you are a member of staff, alumnus, student of an
            associated institution, or other eligible individual you may need to
            purchase Life/Associate membership of the Union in order to join.
          </p>
        </Col>
      </Row>

      <div className="join-steps">
        <Row>
          <Col xs={12} lg={4} className="join-step-col">
            <div className="join-step-body">
              <h2 className="icrpc-title">The First Step</h2>
              <p className="lead">
                Join Imperial Athletes and SPI Rifle &amp; Pistol on the
                Imperial College Union website
              </p>
              <p>
                Membership of SPI Rifle &amp; Pistol, and therefore by extension
                Imperial Athletes, is a requirement for membership of ICRPC.
              </p>
              <p>
                Union memberships run from 1st August to 31st July, and must be
                renewed each year. There is no discount for a partial year of
                membership.
              </p>
              <p>
                Be sure to select the <em>Probationary Membership</em> product
                option when purchasing SPI Rifle &amp; Pistol membership.
              </p>
            </div>
            <div className="join-step-action-inline">
              <UnionMembershipButtons />
            </div>
          </Col>

          <Col xs={12} lg={4} className="join-step-col">
            <div className="join-step-body">
              <h2 className="icrpc-title">The Second Step</h2>
              <p className="lead">
                Complete the ICRPC application form on our website
              </p>
              <p>
                After joining Imperial Athletes and SPI Rifle &amp; Pistol, you
                can then complete our membership application form. This form
                captures the information we need in order to fulfil our legal
                obligations and administer the Club.
              </p>
              <p>
                Please note that the Club Committee makes application decision
                on a case-by-case basis, and your application may be refused.
              </p>
            </div>
            <div className="join-step-action-inline">
              <ApplicationFormButton />
            </div>
          </Col>

          <Col xs={12} lg={4} className="join-step-col">
            <div className="join-step-body">
              <h2 className="icrpc-title">The Third Step</h2>
              <p className="lead">Come along and shoot!</p>
              <p>
                Once we have all of your information we will add you to our
                mailing list where you will receive details of upcoming trips
                and sessions.
              </p>
              <p>
                For your first Club session you will need to bring some piece of
                official ID (e.g. passport, driving licence, BRP) and some
                Imperial College or Union ID (e.g. a College Card or Union
                Life/Associate member card).
              </p>
            </div>
          </Col>
        </Row>

        <Row className="join-step-action-row">
          <Col lg={4}>
            <UnionMembershipButtons />
          </Col>
          <Col lg={4}>
            <ApplicationFormButton />
          </Col>
        </Row>
      </div>
    </Container>
  </>
);

export default JoinPage;
