import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header: React.FC = () => {
  return (
    <Navbar bg="primary" variant="dark">
      <Navbar.Brand href="#">
        <span className="icrpc-title">ICRPC</span>
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={NavLink} to="/">
          Home
        </Nav.Link>
        <Nav.Link as={NavLink} to="/disciplines">
          Disciplines
        </Nav.Link>
        <Nav.Link as={NavLink} to="/join">
          Join
        </Nav.Link>
        <Nav.Link as={NavLink} to="/contact">
          Contact
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default Header;
