import React from "react";

const Footer: React.FC = () => (
  <div className="text-center small">
    &copy; Imperial College &amp; St. Mary's Rifle &amp; Pistol Club{" "}
    {new Date().getFullYear()}
  </div>
);

export default Footer;
