import React from "react";

import Button from "react-bootstrap/Button";

interface ExternalLinkButtonProps {
  href: string;
  text: string;
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({
  href,
  text,
}) => (
  <Button
    variant="primary"
    block
    as="a"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </Button>
);

export default ExternalLinkButton;
