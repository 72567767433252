import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./AppRoutes";

import PageHelmet from "./components/PageHelmet";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import "./App.scss";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      {/* Have a top-level PageHelmet to set defaults
      for any pages which don't have one */}
      <PageHelmet />

      <header>
        <Header />
      </header>
      <main>
        <AppRoutes />
      </main>
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
};

export default App;
