import React from "react";
import { Helmet } from "react-helmet";

const BASE_TITLE = "Imperial College & St. Mary's Rifle & Pistol Club";
const BASE_CANONICAL = "https://www.icrpc.org.uk/";
const DEFAULT_DESCRIPTION =
  "Imperial College & St Mary's Rifle & Pistol Club (ICRPC) is a multi-discipline Home Office Approved Target Shooting Club.";

interface PageHelmetProps {
  title?: string;
  canonicalPath?: string;
  description?: string;
}

const PageHelmet: React.FC<PageHelmetProps> = ({
  title,
  canonicalPath,
  description,
}) => {
  const pageTitle = (title !== undefined ? `${title} - ` : "") + BASE_TITLE;

  const pageCanonical = (() => {
    let propPath = "";

    if (canonicalPath !== undefined && canonicalPath[0] === "/") {
      propPath = canonicalPath.substring(1);
    }

    return BASE_CANONICAL + propPath;
  })();

  const pageDescription =
    description !== undefined ? description : DEFAULT_DESCRIPTION;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <link rel="canonical" href={pageCanonical} />
      <meta name="description" content={pageDescription} />
    </Helmet>
  );
};

export default PageHelmet;
