import React from "react";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const ContactFormSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  message: Yup.string().required("This field is required"),
});

export interface ContactFormValues {
  name: string;
  email: string;
  message: string;
}

interface ContactFormProps {
  submissionSuccess: boolean;
  submissionError: string | null;

  onSubmit: (values: ContactFormValues) => Promise<void>;
}

const ContactFormInner: React.FC<
  ContactFormProps & FormikProps<ContactFormValues>
> = ({
  submissionSuccess,
  submissionError,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
}) =>
  submissionSuccess ? (
    <Alert variant="success" className="text-center">
      Thank you for your submission, we will respond as soon as possible
    </Alert>
  ) : (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="contact-name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          name="name"
          type="text"
          placeholder="Your name"
          isInvalid={touched.name && !!errors.name}
          autoComplete="name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="contact-email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          name="email"
          type="text"
          placeholder="Your email address"
          isInvalid={touched.email && !!errors.email}
          autoComplete="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="contact-message">
        <Form.Label>Message</Form.Label>
        <Form.Control
          name="message"
          type="text"
          as="textarea"
          placeholder="Your message"
          rows={6}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.message}
          isInvalid={touched.message && !!errors.message}
        />
        <Form.Control.Feedback type="invalid">
          {errors.message}
        </Form.Control.Feedback>
      </Form.Group>

      {submissionError !== null && (
        <Alert variant="danger">
          <strong>Submission Error:</strong> {submissionError}
        </Alert>
      )}
      <Button block type="submit" variant="primary" disabled={isSubmitting}>
        Submit
      </Button>
    </Form>
  );

const ContactForm = withFormik<ContactFormProps, ContactFormValues>({
  // Default values
  mapPropsToValues: () => ({ name: "", email: "", message: "" }),

  // Submit handler
  handleSubmit: (values: ContactFormValues, { props: { onSubmit } }) =>
    onSubmit(values),

  validationSchema: ContactFormSchema,
})(ContactFormInner);

export default ContactForm;
