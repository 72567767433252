import { createRoot } from "react-dom/client";

import App from "./App";

const container = document.getElementById("root");

if (container !== null) {
  // TODO: React.StrictMode
  createRoot(container).render(<App />);
} else {
  console.error("Could not find #root to create React root");
}
